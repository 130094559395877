/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import React from "react";
import "./Element.css";

// Element

const Element = ({ className, children, as, mB, mT, mL, mR, pB, pT, pL, pR }) => {
  
    let cn = className ? `${className}` : `Element`;
    let Klass = as ? as : "div"

  // Add margin
  if (mB) {
    cn += ` mB-${mB}`;
  }
  if (mT) {
    cn += ` mT-${mT}`;
  }
  if (mL) {
    cn += ` mL-${mL}`;
  }
  if (mR) {
    cn += ` mR-${mR}`;
  }

  // Add padding
  if (pB) {
    cn += ` pB-${pB}`;
  }
  if (pT) {
    cn += ` pT-${pT}`;
  }
  if (pL) {
    cn += ` pL-${pL}`;
  }
  if (pR) {
    cn += ` pR-${pR}`;
  }

  return <Klass className={cn}>{children}</Klass>;
};

export default Element;
