/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import React from "react"
import "./Row.css"

// Row

const Row = ({ children, className, justifyContent, alignItems }) => {

    let cn = className ? `Row ${className}` : 'Row'

    // Add styles
    let styles = {}
    if (justifyContent) {
        styles["justifyContent"] = justifyContent
    }
    if (alignItems) {
        styles["alignItems"] = alignItems
    }

    return (
        <div className={cn} style={styles}>
            {children}
        </div>
    )
}

export default Row;