/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../MaxWidthContainer";
import NavDrawerButton from "../NavDrawerButton";
import { Link } from "react-router-dom";
import React from "react";
import "./PageHeader.css";
import Row from "../Row";

// Page Header

const PageHeader = ({}) => {

  return (
    <div className="PageHeader">
      <MaxWidthContainer className="container">
        <Row className="mobile-content-container">
          <Link to="/" className="titles row">
            <h1 className="title">Andrew Burnett</h1>
            <h2 className="subtitle">Engineer / designer</h2>
          </Link>
          <NavDrawerButton />
        </Row>
        <Row className="desktop-content-container">
          <Link to="/" className="titles row">
            <h1 className="title">Andrew Burnett</h1>
            <h2 className="subtitle">Software engineer / designer</h2>
          </Link>
          <nav>
            <ul className="row">
              <li>
                <Link to="/">About</Link>
              </li>
              <p className="dotvider">•</p>
              {/* <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://books.andrewburnett.info"
                >
                  Books
                </a>
              </li>
              <p className="dotvider">•</p> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </Row>
      </MaxWidthContainer>
    </div>
  );
};

export default PageHeader;
