/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

// import { FaInfoCircle, FaPen, FaBook } from "react-icons/fa";
import { Squash as Hamburger } from "hamburger-react";
import { FaExternalLinkAlt } from "react-icons/fa";
// import { IoMdContact } from "react-icons/io";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Element from "../Element";
import "./NavDrawerButton.css";
import Row from "../Row";

// Nav Drawer Button

const NavDrawerButton = ({ drawerClassName, buttonClassName }) => {
  let drawerCn = drawerClassName ? `drawer ${drawerClassName}` : "drawer";
  let buttonCn = buttonClassName ? `button ${buttonClassName}` : "button";

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerIsOpen((prevState) => !prevState);
  };

  return (
    <Element className="NavDrawerButton">
      <div className="hamburger-container">
        <Hamburger
          rounded
          size={20}
          distance="lg"
          duration={0.25}
          direction="right"
          className={buttonCn}
          toggled={drawerIsOpen}
          toggle={setDrawerIsOpen}
        />
      </div>
      <Drawer
        size="50vh"
        duration={250}
        direction="bottom"
        open={drawerIsOpen}
        className={drawerCn}
        lockBackgroundScroll
        onClose={toggleDrawer}
        style={{
          boxShadow: "0 0 4px 1px rgb(0 0 0 / 10%)",
        }}
      >
        <Row alignItems="center" justifyContent="center">
          <button className="drag-handle" onClick={toggleDrawer} />
        </Row>
        <nav>
          <ul className="column">
            <li>
              {/* <FaInfoCircle size={16} /> */}
              <Link to="/" onClick={toggleDrawer}>About</Link>
            </li>
            {/* <li>
              <FaBook size={16} />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://books.andrewburnett.info"
              >
                <p>Books</p>
                <FaExternalLinkAlt />
              </a>
            </li> */}
            <li>
              {/* <IoMdContact size={20} /> */}
              <Link to="/contact" onClick={toggleDrawer}>Contact</Link>
            </li>
          </ul>
        </nav>
      </Drawer>
    </Element>
  );
};

export default NavDrawerButton;
