/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import React from "react"
import "./Column.css"

// Column

const Column = ({ children, className, justifyContent, alignItems }) => {

    let cn = className ? `Column ${className}` : 'Column'

    // Add styles
    let styles = {}
    if (justifyContent) {
        styles["justifyContent"] = justifyContent
    }
    if (alignItems) {
        styles["alignItems"] = alignItems
    }

    return (
        <div className={cn} style={styles}>
            {children}
        </div>
    )
}

export default Column;