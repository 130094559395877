/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import MaxWidthContainer from "../../components/MaxWidthContainer";
import PageSubheader from "../../components/PageSubheader";
import ContactForm from "../../components/ContactForm";
import Element from "../../components/Element";
import Column from "../../components/Column";
import Label from "../../components/Label";
import Page from "../../components/Page";
import Row from "../../components/Row";
import "./Contact.css";

// Contact Page

const ContactPage = () => {
  return (
    <Page className="ContactPage">
      <MaxWidthContainer>
        <PageSubheader title={"Contact"} mB={2} />
        <Element className="content" alignItems="flex-start">
          <Column className="by-email">
            <Label className="contact-header" as="h5" mT={6}>
              By email
            </Label>
            <Label className="contact-prompt" as="p" mT={3} mB={5}>
              If you have a question, work inquiry or anything else, please
              don't hesitate to write to andrewjburnett@gmail.com or use the
              form below. I do my best to reply within 1-2 working days.
            </Label>
            <ContactForm />
          </Column>
          <Column className="elsewhere">
            <Label className="contact-header" as="h5" mT={6}>
              Elsewhere
            </Label>
            <Label className="contact-prompt" as="p" mT={3}>
              You can also usually reach me at various other places. Feel free
              to say hello, leave a comment on something or simply stay in
              touch.
            </Label>
            <Column className="contact-callout">
              <Row>
                <a className="icon-link" href="https://twitter.com/andrew__burnett"><FaTwitter className="twitter" size={20} /></a>
                <Label>Talks, links and chats on <a href="https://twitter.com/andrew__burnett">Twitter</a>.</Label>
              </Row>
              <Row>
                <a className="icon-link" href="https://www.linkedin.com/in/itsdrewburnett"><FaLinkedin className="linkedin" size={20} /></a>
                <Label>Career updates and resume on <a href="https://www.linkedin.com/in/itsdrewburnett">LinkedIn</a>.</Label>
              </Row>
              <Row>
                <a className="icon-link" href="https://instagram.com/andrew___burnett"><FaInstagram className="instagram" size={20} /></a>
                <Label>Photos from daily life and travels on <a href="https://instagram.com/andrew___burnett">Instagram</a>.</Label>
              </Row>
            </Column>
          </Column>
        </Element>
      </MaxWidthContainer>
    </Page>
  );
};

export default ContactPage;
