/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import ClipLoader from "react-spinners/ClipLoader";
import React, { useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { BiSend } from "react-icons/bi";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Column from "../Column";
import Label from "../Label";
import "./ContactForm.css";
import Row from "../Row";

// Spinner styling

const LoaderStyles = {
  display: "block",
  margin: "0 auto",
};

// Contact Form

const ContactForm = ({ }) => {
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      emailjs
        .sendForm(
          "service_g7muj9e",
          "contact_form_template",
          form.current,
          "user_DVzg10ePupLekcL0f3L0C"
        )
        .then(
          (result) => {
            // show the user a success message
            toast.success('Your message was delivered')
            form.current.reset();
          },
          (error) => {
            // show the user an error
            toast.error('Send failed — please try again')
          }
        );
      setLoading(false);
    }, 500);
  };

  return (
    <form ref={form} className="ContactForm" onSubmit={handleSubmit}>
      <Row className="input-container row name">
        <label htmlFor="name">Your Name:</label>
        <input
          required
          type="text"
          id="name"
          name="user_name"
          disabled={loading}
        />
      </Row>
      <Row className="input-container row email">
        <label htmlFor="mail">Email Address:</label>
        <input
          required
          type="email"
          id="mail"
          name="user_email"
          disabled={loading}
        />
      </Row>
      <Column className="input-container column message">
        <label htmlFor="msg" className="message">
          Message:
        </label>
        <textarea
          required
          id="msg"
          name="user_message"
          disabled={loading}
        ></textarea>
      </Column>
      <button className="btn send">
        <Row>
          {!loading && (
            <>
              <BiSend />
              <Label>Send</Label>
            </>
          )}
          {loading && (
            <ClipLoader
              size={20}
              color={"#FFFFFF"}
              cssOverride={LoaderStyles}
            />
          )}
        </Row>
      </button>
    </form>
  );
};

export default ContactForm;
