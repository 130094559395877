/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../MaxWidthContainer"
import Element from "../Element"
import "./PageSubheader.css"
import React from "react"

// PageSubheader

const PageSubheader = ({ title, ...props }) => {

    const MobileButtons = () => {
        return (
            <div className="mobile-buttons-container">
                <h2>{title}</h2>
            </div>
        )
    }

    const DesktopButtons = () => {
        return (
            <div className="desktop-buttons-container">
               <h2>{title}</h2>
            </div>
        )
    }

    return (
        <Element className="PageSubHeader" {...props} >
            <MaxWidthContainer className="container">
                <MobileButtons />
                <DesktopButtons />
            </MaxWidthContainer>
        </Element>
    )
}

export default PageSubheader;