/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import React from "react";

// Label

const Label = ({ as, children, ...props }) => {

    return (
        <Element as={as} className="Label" {...props}>
            {children}
        </Element>
    )
}

Label.defaultProps = {
    As: "p"
}

export default Label;