/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import MaxWidthContainer from "../../components/MaxWidthContainer";
import PageSubheader from "../../components/PageSubheader";
import BannerImage from "./images/banner_2.jpg";
import Label from "../../components/Label";
import Page from "../../components/Page";
import "./Home.css";

// Home Page

const HomePage = () => {
	return (
		<Page className="HomePage">
			<MaxWidthContainer>
				<PageSubheader title={"About"} />
				<div className="banner-container">
					<img className="banner" src={BannerImage} />
				</div>
				<Label className="about-label" as="p" mT={8} mB={5}>
					I have a deep interest in aesthetics and embrace a
					minimalistic approach in life, believing firmly that less is
					more.
					<br />
					<br />
					I hold a degree in Computational and Applied Mathematics, a
					foundation that has underpinned my career in technology.
					<br />
					<br />
					Over the past twelve years, my career has evolved from
					freelancing in UI/UX design to co-founding a digital product
					studio in my late 20s. I've built numerous products from
					scratch and have provided engineering and design consultancy
					for a variety of startups and large business.
					<br />
					<br />
					For the past three years, I have worked as a fullstack
					engineer at Trace, an AI video company in the sports sector.
					<br />
					<br />
					Beyond my professional role, I am deeply committed to the
					principles of liberty. I firmly believe in the potential of
					Bitcoin to usher in economic and political freedom across
					the globe. My passion for Bitcoin is driven by a desire to
					see it empower individuals and communities, offering a
					transformative approach to how we understand and interact
					with value and governance.
				</Label>
			</MaxWidthContainer>
		</Page>
	);
};

export default HomePage;
