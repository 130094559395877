/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import "./MaxWidthContainer.css"
import React from "react"

// Max Width Container

const MaxWidthContainer = ({ maxWidth, className, children }) => {

    let cn = className ? `MaxWidthContainer ${className}` : "MaxWidthContainer"
    let styles = maxWidth ? { maxWidth: maxWidth } : {}

    return (
        <div className={cn} style={styles}>
            {children}
        </div>
    )
}

MaxWidthContainer.defaultProps = {
    maxWidth: null,
}

export default MaxWidthContainer;